var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-search-box",
        { on: { enter: _vm.getEnvList } },
        [
          _c("template", { slot: "search" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-select", {
                  attrs: {
                    comboItems: _vm.useFlagItems,
                    type: "search",
                    itemText: "codeName",
                    itemValue: "code",
                    name: "useFlag",
                    label: "사용여부",
                  },
                  model: {
                    value: _vm.searchParam.useFlag,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "useFlag", $$v)
                    },
                    expression: "searchParam.useFlag",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7" },
          [
            _c(
              "c-table",
              {
                ref: "table",
                attrs: {
                  title: "환경설정 목록",
                  tableId: "table",
                  columnSetting: false,
                  isFullScreen: false,
                  columns: _vm.grid.columns,
                  data: _vm.grid.data,
                  isExcelDown: false,
                },
                on: { rowClick: _vm.rowClick },
              },
              [
                _c(
                  "template",
                  { slot: "table-button" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _c("c-btn", {
                          attrs: { label: "검색", icon: "search" },
                          on: { btnClicked: _vm.getEnvList },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5" },
          [
            _c(
              "q-form",
              { ref: "editForm" },
              [
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm",
                    attrs: { title: "상세" },
                  },
                  [
                    _c(
                      "template",
                      { slot: "card-button" },
                      [
                        _c(
                          "q-btn-group",
                          { attrs: { outline: "" } },
                          [
                            _vm.editable
                              ? _c("c-btn", {
                                  attrs: { label: "등록", icon: "add" },
                                  on: { btnClicked: _vm.addEnv },
                                })
                              : _vm._e(),
                            _vm.editable && _vm.deleteable
                              ? _c("c-btn", {
                                  attrs: {
                                    url: _vm.deleteUrl,
                                    isSubmit: true,
                                    param: _vm.data,
                                    mappingType: "DELETE",
                                    label: "삭제",
                                    icon: "remove",
                                  },
                                  on: {
                                    beforeAction: _vm.deleteEnv,
                                    btnCallback: _vm.deleteCallback,
                                  },
                                })
                              : _vm._e(),
                            _vm.editable && _vm.saveable
                              ? _c("c-btn", {
                                  attrs: {
                                    url: _vm.saveUrl,
                                    isSubmit: _vm.isSave,
                                    param: _vm.data,
                                    mappingType: _vm.saveType,
                                    label: "저장",
                                    icon: "save",
                                  },
                                  on: {
                                    beforeAction: _vm.saveEnv,
                                    btnCallback: _vm.saveCallback,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-text", {
                            attrs: {
                              editable:
                                _vm.editable &&
                                !_vm.updateMode &&
                                _vm.dataeditable,
                              required: true,
                              label: "환경설정코드",
                              name: "envCd",
                            },
                            model: {
                              value: _vm.data.envCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "envCd", $$v)
                              },
                              expression: "data.envCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: _vm.editable && _vm.dataeditable,
                              required: true,
                              label: "환경설정명",
                              name: "envName",
                            },
                            model: {
                              value: _vm.data.envName,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "envName", $$v)
                              },
                              expression: "data.envName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: _vm.editable && _vm.dataeditable,
                              required: true,
                              label: "설정값",
                              name: "attrVal01",
                            },
                            model: {
                              value: _vm.data.attrVal01,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "attrVal01", $$v)
                              },
                              expression: "data.attrVal01",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: _vm.editable && _vm.dataeditable,
                              label: "추가속성 2",
                              name: "attrVal02",
                            },
                            model: {
                              value: _vm.data.attrVal02,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "attrVal02", $$v)
                              },
                              expression: "data.attrVal02",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: _vm.editable && _vm.dataeditable,
                              label: "추가속성 3",
                              name: "attrVal03",
                            },
                            model: {
                              value: _vm.data.attrVal03,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "attrVal03", $$v)
                              },
                              expression: "data.attrVal03",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: _vm.editable && _vm.dataeditable,
                              label: "추가속성 4",
                              name: "attrVal04",
                            },
                            model: {
                              value: _vm.data.attrVal04,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "attrVal04", $$v)
                              },
                              expression: "data.attrVal04",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-12" },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: _vm.editable && _vm.dataeditable,
                              label: "설명",
                              name: "description",
                            },
                            model: {
                              value: _vm.data.description,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "description", $$v)
                              },
                              expression: "data.description",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-radio", {
                            attrs: {
                              editable: _vm.editable && _vm.dataeditable,
                              comboItems: _vm.useFlagItems,
                              label: "사용여부",
                              name: "useFlag",
                            },
                            model: {
                              value: _vm.data.useFlag,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "useFlag", $$v)
                              },
                              expression: "data.useFlag",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }